<template>
  <Layout style="background-color:white;">
    <PageHeader :title="title" :items="items" />
    <div class="row">
                <div class="col-md-6" v-for="(item, index) in printerlist" :key="index">
                    <div class="card p-3 mb-2">
                        <div class="d-flex justify-content-between">
                            <div class="d-flex flex-row align-items-center">
                                <div class="icon"> <img src="@/assets/printer.png" width="50" height="50"> </div>
                                <div class="ms-2 c-details">
                                    <h6 class="mb-0">{{ item.name }}</h6> <br>
                                </div>
                            </div>
                            <div class="badge"> <span>{{ (item.isDefault==1) ? "Varsayılan" : "" }}</span> </div>
                        </div>
                        <div class="mt-3">
                            <h3 class="heading">{{ item.printer_make_and_model }}</h3>
                            <br>
                            
                            <p>ID : <b> {{  item.id }} </b></p>
                            <div class="mt-2">
                                <div class="mt-3">{{ item.description }} </div>
                                
                            
                           <p>Client Id : {{ item.hwid}} </p>
                            <p>Yazıcı detayı: {{ item.printer_location }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    <div class="row">
      <div class="col-md-12">

        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Ara:
                    <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                    </b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <div class="table-responsive">
              <b-table :items="transactionData" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage"  :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">

                <template v-slot:cell(base64)="data">
                  <span @click="copyData(data.item.base64)"><i class="ri-file-copy-line"></i>Kopyalamak için tıklayını<z class=""></z></span>
                </template>
                <template v-slot:cell(is_printed)="data">
                  <el-tag :type="data.item.is_printed==1 ? 'success' : 'danger' " >{{data.item.is_printed==1 ? 'Kuyruğa Aktarıldı / İşlem Tamam' : 'Kuyruk Başarısız / İşlem Başarısız' }}</el-tag>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import {axiosInstance} from '../../../helpers/axios';

 
  export default {
    components: {
      Layout,
      PageHeader,

    },
    name:"dashboard",
    data() {
      return {
        intervalId:null,
        title: "Anasayfa",
        items: [{
            text: "OtoPrinter"
          },
          {
            text: "Anasayfa",
            active: true
          }
        ],

        printerlist:[],

        transactionData: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [5, 10, 15, 25, 50],
        filter: null,
        filterOn: [],
        sortDesc: false,
        fields: [{
            key: "printerName",
            label: "Yazıcı Adı"
          },{
            key: "base64",
            label: "Dosya(base64 Format)"
          },{
            key: "datetime",
            sortable: true,
            label: "Kuyruk zamanı"
          },
          {
            key: "is_printed",
            sortable: true,
            label: "Status"
          }          
        ],
       

      };
    },
    mounted() {
      var vm = this;
      vm.totalRows = vm.transactionData.length;
      vm.List();
      this.intervalId = setInterval(() => {
            this.getServisPrinterList();
        }, 10000); 
      vm.getServisPrinterList();

    },
    beforeDestroy() {
        // Bileşen yok edilmeden önce setInterval'i temizlemek önemlidir, aksi takdirde bellek sızıntılarına yol açabilir
        clearInterval(this.intervalId);
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      List()
      {
        var vm = this;
        vm.transactionData=[];
        try {

          axiosInstance.get("printer/queue").then((response)=>{

            if(response.data.error==true)
            {
              vm.$bvToast.toast("Listeleme Başarısız Tekrar deneyiniz ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });

            }else{

              

              if(response.data.data.length>0)
              {
                response.data.data.forEach(element => {
                  vm.transactionData.push({
                    printerName: element.name,
                    base64: element.base64,
                    datetime:element.datetime,
                    is_printed:element.is_printed,
                    id:element.id,
                    hwid:element.hwid
                  });    
                });
              }

            }
          }).catch((error)=>{
            console.log(error);
          })
          
        } catch (error) {
          console.log(error);
        }
      },
      copyData(data) {
        var vm = this;
      navigator.clipboard.writeText(data).then(() => {
        vm.$bvToast.toast("Veri kopyalandı", {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
       });
      }).catch(err => {
        vm.$bvToast.toast("Veri kopyalama hatası"+err, {
                            title: `Uyari !`,
                            variant: "warning",
                            solid: true
        })
      });
      },
      getServisPrinterList()
        {
          var vm = this;
            try {
              axiosInstance.get('printer/lists').then((response) => {
                    if(response.data.error)
                    {
                        
                        vm.$bvToast.toast(response.data.message, {
                          title: `Uyari !`,
                          variant: (response.data.error) ? 'error' : 'success',
                          solid: true
                        });
                    }
                    this.printerlist = response.data.data;
                });
                
            } catch (error) {
              vm.$bvToast.toast(error, {
                          title: `Uyari !`,
                          variant:'error',
                          solid: true
              });
            }
      },
    }
  };
</script>

<style scoped>

.card {
    border: 1px dashed black;
    border-radius: 10px;
    margin-top: 15px;
}

.c-details span {
    font-weight: 300;
    font-size: 13px
}

.icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px
}

.badge span {
    background-color: #fffbec;
    width: 60px;
    height: 25px;
    padding-bottom: 3px;
    border-radius: 5px;
    display: flex;
    color: #fed85d;
    justify-content: center;
    align-items: center
}

.progress {
    height: 10px;
    border-radius: 10px
}

.progress div {
    background-color: red
}

.text1 {
    font-size: 14px;
    font-weight: 600
}

.text2 {
    color: #a5aec0
}</style>
